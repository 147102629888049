

    <header class="main-header">
      <nav class="navbar navbar-static-top">
        <div class="container">
          <div class="navbar-header">
            <a routerLink="/" class="navbar-brand"><b>deliveryApp</b></a>
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse">
              <i class="fa fa-bars"></i>
            </button>
          </div>

          <!-- Collect the nav links, forms, and other content for toggling -->
          <div class="collapse navbar-collapse pull-right" id="navbar-collapse">
            <ul class="nav navbar-nav ">

              <ng-container *ngIf="!auth.isLoggedIn()">
                <li routerLinkActive="active"><a [routerLink]="['/restaurants']">Restaurantes <span class="sr-only">(current)</span></a></li>
                <!-- <li routerLinkActive="active" ><a [routerLink]="['/about']">Sobre</a></li> -->
                <li routerLinkActive="active"><a [routerLink]="['/auth/login']"><i class="fa fa-user"></i> Entrar</a></li>
                <li routerLinkActive="active"><a [routerLink]="['/auth/register']"><i class="fa fa-pencil"></i> Registe-se</a></li>
                <li routerLinkActive="active"><a [routerLink]="['/auth/restaurant/register']">Registe seu restaurante</a></li>
              </ng-container>


              <li routerLinkActive="active"><a [routerLink]="['/restaurants']" *ngIf="auth.isLoggedIn() && auth.currentUser.role=='ROLE_CONSUMIDOR'">Restaurantes</a></li>
               <li routerLinkActive="active" class="dropdown" *ngIf="auth.isLoggedIn() && auth.currentUser.role=='ROLE_CONSUMIDOR'">
                  <a href="#" class="dropdown-toggle" data-toggle="dropdown">{{ auth.currentUser.name }} <span class="caret"></span></a>
                  <ul class="dropdown-menu" role="menu">
                    <li><a [routerLink]="['/custumers']">Minha Conta</a></li>
                    <li><a style="cursor: pointer;" (click)="auth.logout()"> <i class="fa fa-sign-out"></i> Sair</a></li>
                  </ul>
                </li>

               <li routerLinkActive="active" class="dropdown" *ngIf="auth.isLoggedIn() && auth.currentUser.role=='ROLE_RESTAURANTE'">
                  <a href="#" class="dropdown-toggle" data-toggle="dropdown">{{ auth.currentUser.name }} <span class="caret"></span></a>
                  <ul class="dropdown-menu" role="menu">
                    <li><a [routerLink]="['/restaurantadmin']">Minha Conta</a></li>
                    <li><a style="cursor: pointer;" (click)="auth.logout()"> <i class="fa fa-sign-out"></i> Sair</a></li>
                  </ul>
                </li>



            </ul>
          </div>
          <!-- /.navbar-collapse -->

        </div>
        <!-- /.container-fluid -->
      </nav>
    </header>
