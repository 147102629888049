<section class="content login-content" style="margin: 0.5% auto">

  <div class="login-logo">
    <!-- Registar Restaurante -->
    <b>deliveryApp | </b> Restaurante
  </div>

  <div style="margin-bottom:50px" class="col-xs-12 col-sm-offset-2 col-sm-8 login-box-body">

    <p class="login-box-msg">Divulgue os seus produtos com deliveryApp</p>

    <form [formGroup]="signUpForm" (ngSubmit)="onSignUpRestaurant(signUpForm.value)" autocomplete="off" novalidate>

      <div class="row">
        <div class="col-sm-6">
          <div class="form-group has-feedback">

            <app-input error-message="Campo Obrigatório">
              <select class="form-control" formControlName="idsegment">
                <option [ngValue]="null">Selecione a Gastronomia</option>
                <option *ngFor="let segment of segments" value="{{ segment.id }}">{{ segment.segment }}</option>
              </select>
            </app-input>

          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group has-feedback">

            <app-input error-message="Campo obrigatório">
              <input type="text" class="form-control" placeholder="Nome do restaurante" formControlName="name">
            </app-input>

          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group has-feedback">
            <app-input error-message="Campo obrigatório">
              <input type="text" class="form-control" placeholder="Descrição do Restaurante" formControlName="about">
            </app-input>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group has-feedback">

            <app-input error-message="Campo Obrigatório">
              <input type="text" class="form-control" placeholder="Tempo de Entrega de Encomenta" formControlName="deliveryEstimate">
            </app-input>

          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group has-feedback">
            <app-input error-message="Campo obrigatório">
              <input type="text" class="form-control" placeholder="Localização" formControlName="address">
            </app-input>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group has-feedback">

            <app-input error-message="Campo Obrigatório">
              <input type="text" class="form-control" placeholder="Horário de Trabalho" formControlName="hours">
            </app-input>

          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-sm-12">
            <app-input error-message="Campo obrigatório">
                <input type="email" class="form-control" placeholder="Email" formControlName="email">
              </app-input>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="form-group has-feedback">
            <app-input error-message="Campo obrigatório">
              <input type="password" class="form-control" placeholder="Senha" formControlName="password">
            </app-input>

          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group has-feedback">
            <app-input error-message="Campo obrigatório">
              <input type="password" class="form-control" placeholder="Confirmar Senha" formControlName="pwdConfirm">
            </app-input>
            <div class="col-xs-12 col-sm-12">
              <span class="help-block pull-right has-error-block" *ngIf="signUpForm.hasError('passwordsNotMatch')">
                <i class="fa fa-remove"></i>Os Passwords não Conferem</span>
            </div>
          </div>
        </div>
      </div>










      <div class="row">
        <!-- /.col -->
        <div class="col-xs-offset-8 col-xs-4">
            <app-button [formValidation]="signUpForm.invalid"
                                          [buttonIsLoading]="buttonIsLoading" [buttonName]="'Registar'"
                                          [buttonIcon]="'pencil'" [buttonBlock]="'btn-block btn-flat'"></app-button>
        </div>
        <!-- /.col -->
      </div>


    </form>
    <a style="text-decoration: none; cursor: pointer;" routerLink="/auth/login" class="text-center">Já tenho uma conta!
    </a>
  </div>

</section>
