import { Injectable,Injector } from '@angular/core';

import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpClient
} from '@angular/common/http';

import 'rxjs/add/operator/catch';
import {Observable} from'rxjs';
import 'rxjs/RX';
import { AuthService } from '../services/auth.service';
import { environment } from '../../../environments/environment';
@Injectable()

export class RefreshTokenInterceptor implements HttpInterceptor {
    
    constructor(public injector:Injector,public auth:AuthService){}

    intercept(req: HttpRequest<any>, next: HttpHandler) {

        return next.handle(req).catch((errorResponse:HttpErrorResponse)=>{
           
            const error=(typeof errorResponse.error !== 'object')? JSON.parse(errorResponse.error) : errorResponse.error

            if(errorResponse.status===401 && error.error==="token_expired"){
                
                const http=this.injector.get(HttpClient)

                return http.post<any>(`${environment.URL_API}/auth/refresh`,{})

                .flatMap(data=>{
                    
                    localStorage.setItem('token',data.token)
                    const cloneRequest=req.clone({setHeaders:{'Authorization':`Bearer ${data.token}`}})
                    return next.handle(cloneRequest)
                })

              
            }

            return Observable.throw(error)
        })
    }
}