import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AuthService } from '../../../shared/services/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  public signUpForm: FormGroup
  emailPattern=/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  buttonIsLoading: boolean=false;

  constructor(private notificationServ: NotificationService, private auth: AuthService, private fb: FormBuilder, private ativatedRoute: ActivatedRoute, private route: Router) { }

  ngOnInit() {
    this.signUpForm = this.fb.group({
      name: this.fb.control(null, [Validators.required, Validators.minLength(3)]),
      mobile: this.fb.control(null, [Validators.required, Validators.minLength(8)]),
      email: this.fb.control(null, [Validators.required, Validators.pattern(this.emailPattern)]),
      password: this.fb.control(null, [Validators.required, Validators.minLength(8)]),
      pwdConfirm: this.fb.control(null, [Validators.required, Validators.minLength(8)])
    }, { validator: RegisterComponent.equalsTo })
  }

  // Verificando passwords iguais
  static equalsTo(group: AbstractControl): { [key: string]: boolean } {
    const password = group.get('password')
    const pwdConfirm = group.get('pwdConfirm')

    if (!password || !pwdConfirm) {
      return undefined
    }

    if (password.value !== pwdConfirm.value) {
      return { passwordsNotMatch: true }
    }


    return undefined
  }


  onSignUp(signUpForm: any): void {

    this.buttonIsLoading=true

    this.auth.signUpUser(signUpForm)
      .subscribe((registou) => {

        if(registou){
          let returnUrl = this.ativatedRoute.snapshot.queryParamMap.get('returnUrl')
          this.route.navigate([returnUrl || '/'])
          this.notificationServ.notify("Registo efectuado com sucesso")
          this.buttonIsLoading=false
        }

      })
  }

}
