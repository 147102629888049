import { Injectable } from '@angular/core';
import { MenuItem } from 'src/app/pages/restaurants/shared/models/menu-item.model';
import { CartItem } from 'src/app/pages/order/shared/models/cart-item-model';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Injectable()
export class ShoppingCartService {

    public items:CartItem[]=[]

    constructor(private nfs:NotificationService){}
    //Metodo para limpar o carrinho
    public clear():void{
        this.items=[];
    }

    public itens():CartItem[]{
        return this.items
    }

    //Metodo para adicionar Item no Carrinho
    public addItem(menuItem:MenuItem){
        let foundItem=this.items.find((mItem)=>mItem.menuItem.id===menuItem.id);
        if(foundItem){
           foundItem.qtd++;
        }else{
            this.items.push(new CartItem(menuItem));
        }

        this.nfs.notify(`Voce Adicionou o Item ${ menuItem.name } no Carrinho`)
    }

    //Metodo para remover Item no Carrinho
    public removeItem(item:CartItem){
        this.items.splice(this.items.indexOf(item),1);
        this.nfs.notify(`Voce removeu o Item ${ item.menuItem.name } no Carrinho`)
    }

    //totalizando os itens do carrinho
    public total():number{
        return this.items.map(item=>item.value()).reduce((prev,value)=>prev+value,0)
    }

    //Incrementando a qtd do item
    increaseQtd(item:CartItem):void{
        item.qtd=item.qtd+1;
    }
    //Incrementando a qtd do item
    decreaseQtd(item:CartItem):void{
        item.qtd=item.qtd-1;
        if(item.qtd===0){
            this.removeItem(item);
        }
    }
}