import { AgmCoreModule } from '@agm/core';
import { RatingComponent } from './components/rating/rating.component';
import { AuthedGuard } from './guards/authed.guard';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { DataTablesModule } from 'angular-datatables';
import {NgxPaginationModule} from 'ngx-pagination';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";

import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { InputComponent } from './components/input/input.component';
import { RadioComponent } from './components/radio/radio.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RefreshTokenInterceptor } from './interceptors/refresh-token.interceptor';
import { AuthService } from './services/auth.service';

// guards
import {  AuthiGuard } from './guards/auth.guard';
import { NiceDateFormatPipe } from '../pipes/nice-date-format-pipe.pipe';
import { ChartsModule } from 'ng2-charts';
import { TableComponent } from './components/table/table.component';
import { ButtonComponent } from './components/button/button.component';


@NgModule({
  declarations: [SnackbarComponent,InputComponent,RadioComponent,RatingComponent,NiceDateFormatPipe, TableComponent, ButtonComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBKf8CtJtvLUguBgONutF_axUULPiRR28E'
    })
  ],
  exports: [
    // shared modules
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    DataTablesModule,
    ChartsModule,
    NgxPaginationModule,
    AgmCoreModule,
    GooglePlaceModule,

    // shared components
    SnackbarComponent,
    InputComponent,
    RadioComponent,
    RatingComponent,
    TableComponent,
    ButtonComponent,
    NiceDateFormatPipe
  ],

    providers:[JwtHelperService,AuthService,AuthedGuard,AuthiGuard,
    {provide:HTTP_INTERCEPTORS,useClass:TokenInterceptor,multi:true},
    {provide:HTTP_INTERCEPTORS,useClass:RefreshTokenInterceptor,multi:true},
  ]
})
export class SharedModule {}
