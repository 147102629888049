import {  HttpErrorResponse } from '@angular/common/http';
import 'rxjs/add/observable/throw';
import { Observable } from 'rxjs/RX';

export class AplicationErrorHandler{
   

    static handleError(errorResponse:HttpErrorResponse):any{
        // do something with the exception
        let errorMessage:any;

        if((typeof errorResponse.error == 'object')){
            errorMessage=errorResponse.error
            alert(errorMessage)
        }else{

            if(errorResponse.error==='invalid_credentials'){
                errorMessage='Senha ou Email inválido!'
            }else if(errorResponse.error=='token_not_provided' || errorResponse.error=='token_invalid' ||  errorResponse.error=='token_expired'){
                localStorage.clear()
                errorMessage='login'
            }else if(errorResponse.message=='The given data was invalid.'){
                errorMessage='Os dados enviados não são validos'
            }else{errorMessage=errorResponse.error}
        }

        return Observable.throw(errorMessage)

    }


}