import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthiGuard implements CanActivate {

    constructor(public auth:AuthService,public router:Router){}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {

        if(this.auth.isLoggedIn()){
            return true;
        }

        this.router.navigate(['/auth/login'],{queryParams:{returnUrl:state.url}})
        return false
    }
}
