import { CoreModule } from './core/core.module';

import { NgModule,LOCALE_ID, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { registerLocaleData } from '@angular/common';
import localePtMz from '@angular/common/locales/pt';

registerLocaleData(localePtMz, 'pt-MZ');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    AppRoutingModule,
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-MZ' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
