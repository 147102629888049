import { AuthService } from './../../../../shared/services/auth.service';
import { AplicationErrorHandler } from './../../../../app.error-handler';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class RestaurantsService {
  

    constructor(public http : HttpClient,public auth:AuthService){}


    restaurants():Observable<any>{
        return this.http.get<any>(`${environment.URL_API}/restaurants`)
        .catch(AplicationErrorHandler.handleError)
        .map(response=>response.data)
    }

    getActivePromotion(idRestaurant: string):Observable<any> {
        return this.http.get<any>(`${environment.URL_API}/promotions/active/${idRestaurant}`)
        .catch(AplicationErrorHandler.handleError)
        .map(response=>response.data)
    }

    searchedRestaurants(query:string):Observable<any>{
        return this.http.get<any>(`${environment.URL_API}/search/restaurant/${query || 'all'}`)
        .catch(AplicationErrorHandler.handleError)
        .map(response=>response.data)
    }

    getOrders():Observable<any> {
        return this.http.get<any>(`${environment.URL_API}/restaurant/orders/${this.auth.currentUser.id}`)
        .catch(AplicationErrorHandler.handleError)
        .map(response=>response.data)
    }

    updateOrderStatus(status:Object,idOrder:number): any {
        return this.http.post<any>(`${environment.URL_API}/order/status/${idOrder}`,status)
        .catch(AplicationErrorHandler.handleError)
        .map(response=>response.mensagem)
    }

    signUpRestaurant(restaurant: any):Observable<any> {
        return this.http.post<any>(`${environment.URL_API}/create/restaurant`,restaurant)
        .catch(AplicationErrorHandler.handleError)
        .map(response=>response.mensagem)
    }

    segments(): Observable<any> {
        return this.http.get<any>(`${environment.URL_API}/segments`)
        .catch(AplicationErrorHandler.handleError)
        .map(response=>response.data)
    }

    restaurantById(idRestaurant: string):Observable<any> {
        return this.http.get<any>(`${environment.URL_API}/restaurant/${idRestaurant}`)
        .map(response=>response.data)
    }

    restaurantReviews(idRestaurant: string):Observable<any> {
        return this.http.get<any>(`${environment.URL_API}/restaurant/reviews/${idRestaurant}`)
        .map(response=>response.data)
    }

    restaurantMenu(idRestaurant: string): Observable<any> {
        return this.http.get<any>(`${environment.URL_API}/restaurant/menu/${idRestaurant}`)
        .map(response=>response)
    }


}
