   <!-- INÍCIO DO CONTEÚDO -->
   <section class="content-header"></section>

    <section class="content">

      <div class="jumbotron welcome-jumbotron">
         <h1>Bem vindo ao deliveryApp!</h1>
         <p>
           Está com fome? Peça e receba em casa.
         </p>
         <a class="btn btn-danger btn-lg" routerLink="/restaurants">Ver Restaurantes</a>
      </div>

    </section>
    <!-- FIM DO CONTEÚDO -->
