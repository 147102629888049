import { RestaurantsService } from './../../../pages/restaurants/shared/services/restaurants.service';
import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, AbstractControl, FormBuilder } from '@angular/forms';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-restaurant-register',
  templateUrl: './restaurant-register.component.html',
  styleUrls: ['./restaurant-register.component.css']
})
export class RestaurantRegisterComponent implements OnInit {

  
  public signUpForm: FormGroup
  emailPattern=/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  segments=[];
  buttonIsLoading: boolean=false;

  constructor(
    private notifier: NotificationService, 
    private fb: FormBuilder, 
    private restService: RestaurantsService, 
  ) { }

  ngOnInit() {
    this.signUpForm = this.fb.group({
      idsegment: this.fb.control(null, [Validators.required]),
      name: this.fb.control(null, [Validators.required]),
      about: this.fb.control(null, [Validators.required]),
      deliveryEstimate: this.fb.control(null, [Validators.required]),
      address: this.fb.control(null, [Validators.required]),
      hours: this.fb.control(null, [Validators.required]),
      email: this.fb.control(null, [Validators.required, Validators.pattern(this.emailPattern)]),
      password: this.fb.control(null, [Validators.required]),
      pwdConfirm: this.fb.control(null, [Validators.required])
    }, { validator: RestaurantRegisterComponent.equalsTo })

    this.restService.segments()
    .subscribe(segments=>this.segments=segments)

  }

  // Verificando passwords iguais
  static equalsTo(group: AbstractControl): { [key: string]: boolean } {
    const password = group.get('password')
    const pwdConfirm = group.get('pwdConfirm')

    if (!password || !pwdConfirm) {
      return undefined
    }

    if (password.value !== pwdConfirm.value) {
      return { passwordsNotMatch: true }
    }


    return undefined
  }


  onSignUpRestaurant(signUpForm: FormGroup): void {
    this.buttonIsLoading=true
    this.restService.signUpRestaurant(signUpForm)
    .subscribe((message)=>{
      this.notifier.notify(message)
      this.buttonIsLoading=false
      this.signUpForm.reset()
    })
   
  }


}
