import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {

  @Input() buttonIsLoading:boolean=false
  @Input() formValidation:boolean=false
  @Input() buttonIcon:string='save'
  @Input() buttonBlock:string=''
  @Input() buttonType:string='submit'
  @Input() buttonName:string='Salvar'
  @Input() buttonLoadingName:string='processando'

  constructor() { }

  ngOnInit() {
  }

}
