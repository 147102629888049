
<div class="wrapper">
  <app-header></app-header>
  <!-- Full Width Column -->
  <div class="content-wrapper">
    <div class="container">

      <router-outlet></router-outlet>

    </div>
    <!-- /.container -->
  </div>
  <app-snackbar></app-snackbar>
  <!-- /.content-wrapper -->
  <footer class="main-footer">
    <div class="container">
      <div class="pull-right hidden-xs">
        <b>Version</b> 1.0.0
      </div>
      <strong>Copyright &copy; 2018-2019 <a href="http://almsaeedstudio.com">Delivery App</a>.</strong> All rights
      reserved.
    </div>
    <!-- /.container -->
  </footer>
</div>
<!-- ./wrapper -->
