import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public notifier:EventEmitter<any>=new EventEmitter<any>()

  public emitirEvento:EventEmitter<boolean>=new EventEmitter<boolean>()

  constructor() {}

  notify(message:any){
    this.notifier.emit(message)
  }

  notifyAddress(message:boolean){
    this.emitirEvento.emit(message)
  }
}
