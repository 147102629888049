import { NotificationService } from 'src/app/shared/services/notification.service';
import { HttpErrorResponse } from '@angular/common/http/';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup
  emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  buttonIsLoading: boolean=false;

  constructor(
    public formBuilder: FormBuilder,
    public auth: AuthService,
    public aroute: ActivatedRoute,
    public router: Router,
    public ns: NotificationService) { }


  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: this.formBuilder.control('', [Validators.required, Validators.pattern(this.emailPattern)]),
      password: this.formBuilder.control('', [Validators.required, Validators.minLength(6)])
    })
  }

  onLogin(loginForm: FormGroup) {
    this.buttonIsLoading = true

    this.auth.login(loginForm).subscribe(
      (user: any) => {
        this.ns.notify('Login efectuado com sucesso!')
        this.buttonIsLoading = false
        if (user.role == 'ROLE_CONSUMIDOR') {

          let returnUrl = this.aroute.snapshot.queryParamMap.get('returnUrl')
          this.router.navigate([returnUrl || '/'])

        } else {

          let returnUrl = this.aroute.snapshot.queryParamMap.get('returnUrl')
          this.router.navigate([returnUrl || '/restaurantadmin'])

        }

      },
      (errorMessage: HttpErrorResponse) => {
        this.ns.notify(errorMessage)
        this.buttonIsLoading = false
      }
    )
  }


}
