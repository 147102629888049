import { Injectable } from '@angular/core';

import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler) {

        const token=localStorage.getItem('token');

        const requestUrl=req.url.split('/')
        const apiUrl=environment.URL_API.split('/')

        if(token && apiUrl[2]==requestUrl[2]){
            const newRequest=req.clone({setHeaders:{'Authorization':`Bearer ${token}`}})
            return next.handle(newRequest);
        }

        return next.handle(req);
        
    }

}
