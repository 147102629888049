<section class="content login-content" style="margin: 0.5% auto">

      <div class="login-logo">
            <b>deliveryApp</b> | Clientes
      </div>

      <div style="margin-bottom:50px" class="col-xs-12 col-sm-offset-2 col-sm-8 login-box-body">

            <p class="login-box-msg">Registe-se para fazer encomendas</p>

            <form [formGroup]="signUpForm" (ngSubmit)="onSignUp(signUpForm.value)" autocomplete="off" novalidate>

                  <div class="row">
                        <div class="col-sm-12">
                              <div class="form-group has-feedback">

                                    <app-input error-message="o nome deve ter no minímo 3 caracteres">
                                          <input type="text" class="form-control" placeholder="Nome e Apelido"
                                                formControlName="name">
                                          <span class="fa fa-user form-control-feedback"></span>
                                    </app-input>

                              </div>
                        </div>

                  </div>
                  <div class="row">
                        <div class="col-sm-6">
                              <div class="form-group has-feedback">
                                    <app-input error-message="o telefone deve ter no minimo 9 caracteres">
                                          <input type="text" class="form-control" placeholder="Telefone"
                                                formControlName="mobile">
                                          <span class="fa fa-phone form-control-feedback"></span>
                                    </app-input>
                              </div>
                        </div>
                        <div class="col-sm-6">
                              <div class="form-group has-feedback">

                                    <app-input error-message="Email Inválido">
                                          <input type="email" class="form-control" placeholder="Email"
                                                formControlName="email">
                                          <span class="fa fa-envelope form-control-feedback"></span>
                                    </app-input>

                              </div>
                        </div>

                  </div>
                  <div class="row">
                        <div class="col-sm-6">
                              <div class="form-group has-feedback">
                                    <app-input error-message="o password deve ter no minimo 8 caracteres">
                                          <input type="password" class="form-control" placeholder="Senha"
                                                formControlName="password">
                                          <span class="fa fa-lock form-control-feedback"></span>
                                    </app-input>

                              </div>
                        </div>
                        <div class="col-sm-6">
                              <div class="form-group has-feedback">
                                    <app-input error-message="o password deve ter no minimo 8 caracteres">
                                          <input type="password" class="form-control" placeholder="Confirmar Senha"
                                                formControlName="pwdConfirm">
                                          <span class="fa fa-sign-in form-control-feedback"></span>
                                    </app-input>

                                    <div class="col-xs-12 col-sm-12">
                                          <span class="help-block pull-right has-error-block"
                                                *ngIf="signUpForm.hasError('passwordsNotMatch')"><i
                                                      class="fa fa-remove"></i>Os
                                                Passwords não Conferem</span>
                                    </div>

                              </div>
                        </div>
                  </div>


                  <div class="row">
                        <!-- /.col -->
                        <div class="col-xs-offset-8 col-xs-4">

                                    <app-button [formValidation]="signUpForm.invalid"
                                          [buttonIsLoading]="buttonIsLoading" [buttonName]="'Registar'"
                                          [buttonIcon]="'pencil'" [buttonBlock]="'btn-block'"></app-button>
                        </div>
                        <!-- /.col -->
                  </div>


            </form>

            <a style="text-decoration: none; cursor: pointer;" routerLink="/auth/login" class="text-center">Já tenho uma
                  conta!</a>
      </div>

</section>