import { RestaurantRegisterComponent } from './core/components/restaurant-register/restaurant-register.component';
import { RegisterComponent } from './core/components/register/register.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './core/components/about/about.component';
import { HomeComponent } from './core/components/home/home.component';
import { LoginComponent } from './core/components/login/login.component';
import { AuthedGuard } from './shared/guards/authed.guard';
import { NotFoundPageComponent } from './core/components/not-found-page/not-found-page.component';
import { AuthiGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {path:'',component:HomeComponent},
  {path:'about',component:AboutComponent},
  {path:'auth',children:[
    {path:'',redirectTo:'login',pathMatch:'full'},
    {path:'login',component:LoginComponent,canActivate:[AuthedGuard]},
    {path:'register',component:RegisterComponent,canActivate:[AuthedGuard]},
    {path:'restaurant/register',component:RestaurantRegisterComponent},
  ]},
  {path:'about',component:AboutComponent},
  {path:'restaurants',loadChildren:'./pages/restaurants/restaurants.module#RestaurantsModule'},
  {path:'custumers',loadChildren:'./pages/admin/user/users.module#UsersModule',canActivate:[AuthiGuard]},
  {path:'restaurantadmin',loadChildren:'./pages/admin/restaurant/restaurant.module#RestaurantModule',canActivate:[AuthiGuard]},
  {path:'order/:id',loadChildren:'./pages/order/order.module#OrderModule',canActivate:[AuthiGuard]},
  {path:'**',component:NotFoundPageComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
