 <!-- INÍCIO DO CONTEÚDO -->
 <section class="content-header">
  </section>
  <!-- Main content -->
  <section class="content">

    <div class="jumbotron">
      <img src="assets/img/404.png" /><h2>Página Não Encontrada</h2>
      <p>
        Está com fome? Acesse a página de <a routerLink="/restaurants">ir a página de restaurantes</a>.
      </p>
    </div>

  </section>
<!-- FIM DO CONTEÚDO -->