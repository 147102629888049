import { AplicationErrorHandler } from './../../app.error-handler';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()

export class AuthService {


    constructor(public jwtHelper:JwtHelperService,public http:HttpClient,public ns:NotificationService,public router:Router){}

    login(credentials):Observable<any>{
       return  this.http.post<any>(`${environment.URL_API}/auth/login`,credentials)
       .catch(AplicationErrorHandler.handleError)
       .map(response=>{
            localStorage.setItem('token',response.token)
            localStorage.setItem('user',btoa(JSON.stringify(response.data[0])))
            return response.data[0]
        })

    }

    signUpUser(user: any): Observable<boolean> {
        return  this.http.post<any>(`${environment.URL_API}/create/user`,user)
        .catch(AplicationErrorHandler.handleError)
        .map(response=>{
             localStorage.setItem('token',response.data.token)
             localStorage.setItem('user',btoa(JSON.stringify(response.data)))
             return true
         })
    }

    isLoggedIn():boolean{

        if(localStorage.getItem('user') && localStorage.getItem('token')){
            return true
        }
        return false
    }

    get currentUser(){
       return JSON.parse(atob(localStorage.getItem('user')))
    }

    logout():void {
        if(confirm('Você realmente deseja sair?')){

            this.http.get<any>(`${environment.URL_API}/auth/logout`)
            .subscribe((response)=>{
                localStorage.clear();

                this.router.navigateByUrl('/auth/login').then(()=>{
                    this.ns.notify(response.mensagem)
                })
            })
        }
    }

    setUser():void{
        this.http.get<any>(`${environment.URL_API}/user/authenticated`)
        .subscribe(response =>{

            if(response.userdata.length > 0){

                localStorage.setItem('user',btoa(JSON.stringify(response.userdata[0])))
            }else{
                localStorage.setItem('user',btoa(JSON.stringify(response.restaurantdata[0])))
            }
         })
    }

    getUser():Observable<any>{
        return this.http.get<any>(`${environment.URL_API}/user/authenticated`)
        .map(response=>response.data)
        .catch(AplicationErrorHandler.handleError)
    }


}
