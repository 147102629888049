import { CrudService } from './components/shared/service/crud.service';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { RestaurantRegisterComponent } from './components/restaurant-register/restaurant-register.component';
import { JwtModule } from '@auth0/angular-jwt';
import { ShoppingCartService } from '../core/components/shared/service/shopping-cart.service';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { RestaurantsService } from 'src/app/pages/restaurants/shared/services/restaurants.service';
import { WebSocketService } from '../shared/services/web-socket.service';

export function tokenGetter() {
  return localStorage.getItem("token");
}

@NgModule({
  declarations: [HeaderComponent, HomeComponent, AboutComponent, LoginComponent, RegisterComponent, RestaurantRegisterComponent, NotFoundPageComponent],
  imports: [
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      }
    })
  ],
  exports:[
    // shared modules
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // shared components
    HeaderComponent,
    HomeComponent,
    AboutComponent,
    LoginComponent,
    RegisterComponent,
    RestaurantRegisterComponent,
    NotFoundPageComponent
  ],
  providers:[ShoppingCartService,CrudService,RestaurantsService,WebSocketService]

})
export class CoreModule { }
