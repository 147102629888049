<button 
[type]="buttonType" 
style="background-color:white; border:1px solid  rgb(240, 50, 50);" 
[disabled]="formValidation || buttonIsLoading" 
class="btn btn-md {{buttonBlock}}"
>

  <span *ngIf="!buttonIsLoading">
    <i class="fa fa-{{buttonIcon}}"></i>
    {{ buttonName }}
  </span>

  <span *ngIf="buttonIsLoading">
    <i class="fa fa-spinner fa-spin"></i>
    {{ buttonLoadingName }}
  </span>

</button>

