
      <!-- INÍCIO DO CONTEÚDO -->
      <section class="content login-content" style="margin: 5% auto">
          <div class="login-logo">
               <b>deliveryApp</b> | Entrar
          </div>
          <div class="col-xs-12 col-sm-offset-3 col-sm-6 login-box-body">

          <p class="login-box-msg"> Por favor, efetue o login para continuar</p>

          <form [formGroup]="loginForm" (ngSubmit)="onLogin(loginForm.value)" autocomplete="off" novalidate>

             <app-input error-message="Email invalido">
                <input type="email" class="form-control" placeholder="Email" formControlName="email">
                <span class="glyphicon glyphicon-envelope form-control-feedback"></span>
             </app-input>

             <app-input error-message="A password deve ter no minimo 6 caracteres">
                <input type="password" class="form-control" placeholder="Senha" formControlName="password">
                <span class="glyphicon glyphicon-lock form-control-feedback"></span>
             </app-input>

            <div class="row">
            <!-- /.col -->
            <div class="col-xs-offset-8 col-xs-4">
              <app-button 
                  [formValidation]="loginForm.invalid" 
                  [buttonIsLoading]="buttonIsLoading"
                  [buttonName]="'Entrar'"
                  [buttonIcon]="'sign-in'"
                  [buttonBlock]="'btn-block'"
              ></app-button>
            </div>
            <!-- /.col -->
            </div>
          </form>
          <a style="text-decoration: none; cursor: pointer;" routerLink="/auth/register" class="text-center">Não tenho conta!</a>
          </div>
        </section>
        <!-- FIM DO CONTEÚDO -->
