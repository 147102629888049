import { Component, OnInit } from '@angular/core';
import { trigger,state,style,animate,transition } from '@angular/animations';
import { Observable } from 'rxjs/RX';

import 'rxjs/add/observable/timer';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/switchMap';
import { NotificationService } from 'src/app/shared/services/notification.service';


  @Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.css'],
    animations:[
      trigger('snack',[
        state('hidden',style({ opacity:0,bottom:'0px' })),
        state('visible',style({opacity:1,bottom:'30px'})),
        transition('hidden=>visible',animate('500ms ease-in')),
        transition('visible=>hidden',animate('500ms ease-out'))
      ])
    ]
  })

export class SnackbarComponent implements OnInit {

  public message:string='Hello There!!!'

  public snackVisibility:string='hidden'

  constructor(private nfs:NotificationService) { }

  ngOnInit() {
    this.nfs.notifier.do((message)=>{
      this.message=message
      this.snackVisibility='visible'
    }).switchMap(message=>Observable.timer(4000))
    .subscribe(timer=>this.snackVisibility='hidden')
  }

}
