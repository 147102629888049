import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/RX';

@Injectable()
export class CrudService {
    public angularCrud:BehaviorSubject<any>=new BehaviorSubject<any>('');

    constructor() {}

    emitObject(obj: any): void {
        this.angularCrud.next(obj)
    }
}